import React from "react";
import { Link, withPrefix } from "gatsby";
import Layout from "../parts/Layout";
import { PageComponentProps } from "../../types/types";
import { NextPageLink } from "../parts/NextPageLink";

// @ts-ignore
import ChordsMajorRootPositionDn from "../../images/svg-dn/Clairnote-chords-major-root-position.svg";
// @ts-ignore
import ChordsMajorRootPositionSn from "../../images/svg-sn/Clairnote-chords-major-root-position.svg";

// @ts-ignore
import ChordsMajorFirstInversionDn from "../../images/svg-dn/Clairnote-chords-major-first-inversion.svg";
// @ts-ignore
import ChordsMajorFirstInversionSn from "../../images/svg-sn/Clairnote-chords-major-first-inversion.svg";

// @ts-ignore
import ChordsMajorSecondInversionDn from "../../images/svg-dn/Clairnote-chords-major-second-inversion.svg";
// @ts-ignore
import ChordsMajorSecondInversionSn from "../../images/svg-sn/Clairnote-chords-major-second-inversion.svg";

// @ts-ignore
import ChordsMinorRootPositionDn from "../../images/svg-dn/Clairnote-chords-minor-root-position.svg";
// @ts-ignore
import ChordsMinorRootPositionSn from "../../images/svg-sn/Clairnote-chords-minor-root-position.svg";

// @ts-ignore
import ChordsMinorFirstInversionDn from "../../images/svg-dn/Clairnote-chords-minor-first-inversion.svg";
// @ts-ignore
import ChordsMinorFirstInversionSn from "../../images/svg-sn/Clairnote-chords-minor-first-inversion.svg";

// @ts-ignore
import ChordsMinorSecondInversionDn from "../../images/svg-dn/Clairnote-chords-minor-second-inversion.svg";
// @ts-ignore
import ChordsMinorSecondInversionSn from "../../images/svg-sn/Clairnote-chords-minor-second-inversion.svg";

// @ts-ignore
import JazzChordsSvgDn from "../../images/svg-dn/Clairnote-jazz-chords.svg";
// @ts-ignore
import JazzChordsSvgSn from "../../images/svg-sn/Clairnote-jazz-chords.svg";

import * as pageStyles from "../global-styles/page.module.css";

export const Chords = (props: PageComponentProps) => {
  const { clairnoteName, urlDir, dn, sn } = props;
  const title = "Chords";
  const description = `In ${clairnoteName} music notation it is easy to see the interval patterns that make up chords and to tell one type of chord from another.`;
  return (
    <Layout title={title} description={description} {...props}>
      <article className="page">
        <h1 className={`title is-3 ${pageStyles.pageTitle}`}>{title}</h1>
        <div>
          <p>
            Since{" "}
            <Link title="Intervals" to={urlDir + "intervals/"}>
              intervals
            </Link>{" "}
            are easy to recognize in {clairnoteName}, it is easy to see the
            interval patterns that make up chords and to tell one type of chord
            from another.
          </p>
          <div className={pageStyles.imageContainer}>
            <img
              src={dn ? ChordsMajorRootPositionDn : ChordsMajorRootPositionSn}
              alt={
                "Major triads, root position in " +
                clairnoteName +
                " music notation"
              }
            />
          </div>
          <div className={pageStyles.imageContainer}>
            <img
              src={dn ? ChordsMinorRootPositionDn : ChordsMinorRootPositionSn}
              alt={
                "Minor triads, root position in " +
                clairnoteName +
                " music notation"
              }
            />
          </div>
          <p>
            The most basic chords are called triads and contain three notes. In
            their simplest form, known as "root position" (shown above), they
            are two thirds stacked on top of each other. A major triad is a
            major third (4 semitones) with a minor third (3 semitones) on top of
            it. In a minor triad it is the opposite, a minor third with a major
            third on top of it. In both cases the highest note, known as the
            fifth of the chord, is a perfect fifth (7 semitones) above the
            lowest note, known as the root note. The middle note is known as the
            third of the chord since it is a third above the root note.
          </p>
          <p>
            These differences between chords are easy to see in {clairnoteName}{" "}
            because the interval patterns that make up chords are readily
            apparent. In traditional notation all thirds look alike so major and
            minor triads look alike and it is difficult to determine which are
            major and which are minor.
          </p>
          <h2 className="title is-5">
            Major Triads: Root Position, First Inversion, Second Inversion
          </h2>
          <div className={pageStyles.imageContainer}>
            <img
              src={dn ? ChordsMajorRootPositionDn : ChordsMajorRootPositionSn}
              alt={
                "Major triads, root position in " +
                clairnoteName +
                " music notation"
              }
            />
          </div>
          <div className={pageStyles.imageContainer}>
            <img
              src={
                dn ? ChordsMajorFirstInversionDn : ChordsMajorFirstInversionSn
              }
              alt={
                "Major triads, first inversion in " +
                clairnoteName +
                " music notation"
              }
            />
          </div>
          <div className={pageStyles.imageContainer}>
            <img
              src={
                dn ? ChordsMajorSecondInversionDn : ChordsMajorSecondInversionSn
              }
              alt={
                "Major triads, second inversion in " +
                clairnoteName +
                " music notation"
              }
            />
          </div>
          <p>
            In addition to root position, triads can appear in two other
            positions known as first inversion and second inversion. Moving the
            lowest note up an octave will change a triad from root position to
            first inversion. Doing it again will change it to second inversion.
          </p>
          <p>
            Notes an octave apart look the same in {clairnoteName} which makes
            it easy to see these patterns and recognize that the notes are the
            same three notes, just placed in different octaves. In traditional
            notation notes an octave apart do not resemble each other, making
            this more difficult.
          </p>
          <h2 className="title is-5">
            Minor Triads: Root Position, First Inversion, Second Inversion
          </h2>
          <div className={pageStyles.imageContainer}>
            <img
              src={dn ? ChordsMinorRootPositionDn : ChordsMinorRootPositionSn}
              alt={
                "Minor triads, root position in " +
                clairnoteName +
                " music notation"
              }
            />
          </div>
          <div className={pageStyles.imageContainer}>
            <img
              src={
                dn ? ChordsMinorFirstInversionDn : ChordsMinorFirstInversionSn
              }
              alt={
                "Minor triads, first inversion in " +
                clairnoteName +
                " music notation"
              }
            />
          </div>
          <div className={pageStyles.imageContainer}>
            <img
              src={
                dn ? ChordsMinorSecondInversionDn : ChordsMinorSecondInversionSn
              }
              alt={
                "Minor triads, second inversion in " +
                clairnoteName +
                " music notation"
              }
            />
          </div>
          <p>
            Since the notes in the inverted chords remain the same (except for
            their octave) the chords are still the same chords (e.g. still a "C
            major" chord). Only their position has changed.
          </p>
          <p>
            The notes do not necessarily have to be in the exact positions shown
            in these illustrations. For example, some could be placed two or
            more octaves higher rather than just one. A triad's position is
            determined solely by which note is lowest. If the root note is the
            lowest then the chord is in root position. If the the third of the
            chord is lowest, then it is in first inversion. If the fifth of the
            chord is lowest, then it is in second inversion.
          </p>
          <p>
            The name of a chord is based on the name of its root note,
            regardless of its position. To determine the name and position of a
            chord, mentally transpose the notes into root position (a stack of
            thirds) to identify which note is the root and which are the third
            and fifth of the chord.
          </p>
          <h2 className="title is-5">More Complex Chords</h2>
          <p>
            Many more types of chords can be built by altering or adding notes
            to the basic triads. The following illustration shows 46 different
            variations on a C chord, many of which are used in jazz music. They
            are shown in {clairnoteName} and in traditional music notation for
            comparison. (Thanks to Pierre Schneidy for sharing the{" "}
            <a href="https://lists.gnu.org/archive/html/lilypond-user/2014-05/msg00322.html">
              original file
            </a>{" "}
            illustrating these chords with the LilyPond user list.) Unlike the
            images above, which omit accidental signs for the sake of
            explanation, this illustration includes {clairnoteName}
            's{" "}
            <Link title="Accidental Signs" to={urlDir + "accidental-signs/"}>
              accidental signs
            </Link>
            .
          </p>
          <p>
            It is easier to see the interval structures of these chords in{" "}
            {clairnoteName}, than it is in traditional notation, which makes it
            easier to understand the chords and tell them apart.
          </p>
          <p>
            You can also listen to what these chords sound like and/or check out
            a{" "}
            <a
              href={withPrefix(
                "pdf/" + (sn ? "sn" : "dn") + "/Chords-in-Clairnote.pdf"
              )}
              target="_blank"
              rel="noopener noreferrer"
            >
              PDF version
            </a>
            .
          </p>
          <p>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <audio controls={true}>
              <source
                src={withPrefix("audio/Chords-in-Clairnote.mp3")}
                type="audio/mp3"
              />
              <source
                src={withPrefix("audio/Chords-in-Clairnote.ogg")}
                type="audio/ogg"
              />
              Your browser does not support the HTML5 <code>audio</code>{" "}
              element.
            </audio>
          </p>
          <div className={pageStyles.imageContainer}>
            <img
              src={dn ? JazzChordsSvgDn : JazzChordsSvgSn}
              alt={"47 Jazz chords in " + clairnoteName + " music notation"}
            />
          </div>

          <NextPageLink title="Clefs" to={urlDir + "clefs/"} />
        </div>
      </article>
    </Layout>
  );
};
