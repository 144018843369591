import { PageProps } from "gatsby";
import React from "react";

import { Chords } from "../components/pages/Chords";

import { snProps } from "../js/utils";

const ChordsPage = (props: PageProps) => (
  <Chords {...{ ...snProps, ...props }} />
);

export default ChordsPage;
